import * as Yup from "yup"
import {
  getCustomValidation,
  getDefaultValidation,
} from "../../../../services/validations"

export const formSchema = ({ fields, t }) => {
  let validationSchema = Yup.object()

  for (let field of fields) {
    let fieldValidation = getDefaultValidation({ field, t })
    fieldValidation = getCustomValidation({ field, fieldValidation })

    validationSchema = validationSchema.concat(
      Yup.object().shape({
        [field.name]: fieldValidation,
      })
    )
  }

  if (Object.keys(validationSchema.fields).includes("hospitalName"))
    validationSchema = validationSchema.concat(
      Yup.object().shape({
        otherHospital: Yup.string().when("hospitalName", {
          is: (hospitalName) => hospitalName.value === "Other",
          then: (schema) => schema.trim().required("This field is required"),
          otherwise: (schema) => schema,
        }),
      })
    )

  return validationSchema
}
